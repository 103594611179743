import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import { Component, Hero, LeftRightContent } from "@components";
import FooterSections from "@shared/footer/footerSections";
import { Container, PageWidth, Section } from "@util/standard";
import { BlocksContent, Button } from "@global";
import { colorsRGB, TABLET_BREAKPOINT, TWENTY_INCH_SCREEN } from "@util/constants";
import { em } from "@util/helper";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const SectionStyled = styled(Section)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-top: 1.5px solid ${colorsRGB.main(0.2)};
  }
`;

const Careers = styled(Container)`
  display: grid;
  grid-template-columns: 2fr 4fr 1fr;
  border-bottom: 1.5px solid ${colorsRGB.main(0.2)};
  align-items: center;

  &:last-child {
    border-bottom: 0;
  }

  > div {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .titleWrapper {
    height: 100%;
    border-right: 1.5px solid ${colorsRGB.main(0.2)};
    padding-right: 50px;
  }

  h3 {
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    h3 {
      font-size: ${em(25, 16)};
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-bottom: 35px;
    margin-bottom: 35px;

    > div {
      padding: 0;
      justify-content: flex-start;
    }

    .titleWrapper {
      border: none;
    }
  }
`;

export default function CareersTemplate({ data, pageContext }: Props) {
  if (!data.sanityCareersPage) return null;

  const { pageMeta, leftRightContent, listings, components } = data.sanityCareersPage;

  console.log("listings", listings);

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} />
      {leftRightContent && <LeftRightContent content={leftRightContent} />}
      {listings && (
        <SectionStyled>
          <PageWidth>
            <Container flexDirection="column" width="100%">
              {listings.length > 0 ? (
                listings.map(listing => {
                  if (!listing) return;

                  const { title, blockContent, link } = listing;

                  return (
                    <Careers key={listing._key}>
                      <Container className="titleWrapper" alignItems="center">
                        <h3>{title}</h3>
                      </Container>
                      <Container padding="50px">
                        <BlocksContent data={blockContent} />
                      </Container>
                      <Container justifyContent="flex-end">
                        <Button {...link} />
                      </Container>
                    </Careers>
                  );
                })
              ) : (
                <Container width="100%" justifyContent="center">
                  There are no career listing available at the moment.
                </Container>
              )}
            </Container>
          </PageWidth>
        </SectionStyled>
      )}
      {components &&
        components.map(component => {
          if (component == null) return null;
          return <Component key={component._key} data={component} />;
        })}
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query CareersQuery {
    sanityCareersPage {
      pageMeta {
        ...sanityPageMeta
      }
      leftRightContent {
        ...sanityLeftRightContent
      }
      listings {
        ...sanityCta
      }
      components {
        ...sanityComponents
      }
    }
  }
`;
